@media (min-width: 0px) {
  .profileImage {
    width: 120px;
    height: 120px;
    border-radius: 100%;
  }
  .copyButton {
    color: #000;
    font-size: 12px;
    font-weight: 500;
    border: 2px solid rgba(0, 0, 0, 0.16);
    width: 60%;
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: center;
    border-radius: 5px;
  }
}
@media (min-width: 600px) {
  .profileImage {
    width: 130px;
    height: 130px;
    border-radius: 100%;
  }
  .copyButton {
    color: #000;
    font-size: 12px;
    font-weight: 500;
    border: 2px solid rgba(0, 0, 0, 0.16);
    width: 40%;
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: center;
    border-radius: 5px;
  }
}
@media (min-width: 900px) {
  .profileImage {
    min-width: 150px;
    min-height: 150px;
    border-radius: 100%;
  }
  .copyButton {
    color: #000;
    font-size: 12px;
    font-weight: 500;
    border: 2px solid rgba(0, 0, 0, 0.16);
    width: 25%;
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: center;
    border-radius: 5px;
  }
}
@media (min-width: 1200px) {
  .profileImage {
    width: 130px;
    height: 130px;
    border-radius: 100%;
    border: 1px solid #d9d9d9;
    padding: 5px;
  }
  .copyButton {
    color: #000;
    font-size: 12px;
    font-weight: 500;
    border: 2px solid rgba(0, 0, 0, 0.16);
    width: 12%;
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: center;
    border-radius: 5px;
  }
}
@media (min-width: 1536px) {
  .profileImage {
    width: 130px;
    height: 130px;
    border-radius: 100%;
    border: 1px solid #d9d9d9;
    padding: 5px;
  }
  .copyButton {
    color: #000;
    font-size: 12px;
    font-weight: 500;
    border: 2px solid rgba(0, 0, 0, 0.16);
    width: 10%;
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: center;
    border-radius: 5px;
  }
}
