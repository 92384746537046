.App {
  text-align: center;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.Workinprogress {
  width: 100%;
}
.errordiv {
  display: flex;
  justify-content: center;
}
.error500 {
  width: 50%;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.infinite-scroll-component__outerdiv {
  /* Your styles here */
  overflow-x: hidden;
  padding-bottom: 50px;
  width: 100%;
}
.dashboard{
  -webkit-user-select: none;
}
input[type="file"]::-webkit-file-upload-button {
  cursor: pointer;
  visibility: hidden;
}

input[type="file"]::before {
  content: 'Choose File';
  display: inline-block;
  background: #007bff;
  color: #fff;
  border: none;
  padding: 8px 20px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  border-radius: 5px;
}

input[type="file"]:hover::before {
  background: #0056b3;
}
.player{
  width: 340px !important;
}
.timeline::-webkit-scrollbar {
  display: none;
}
.payment::-webkit-scrollbar {
  display: none;
}
.button{
  display: none;
  left: 150px;
  bottom: 220px;
}
@media screen and (max-width: 1468px){
  .player {
    width: 326px !important;
}
.button{
  display: none;
  left: 140px;
  bottom: 200px;
}
.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input[type=number] {
  -moz-appearance: textfield;
}
}
@media screen and (max-width: 1418px){
  .player {
    /* max-width: 500px !important;/ */
    width: 316px !important;
}
.button{
  display: none;
  left: 130px;
  bottom: 200px;
}
}
@media screen and (max-width: 1390px){
  .player {
    /* max-width: 500px !important;/ */
    width: 300px !important;
}
.button{
  display: none;
  left: 120px;
  bottom: 200px;
}
}
@media screen and (max-width: 1280px){
  .player {
    /* max-width: 500px !important;/ */
    width: 100% !important;
}
.button{
  display: none;
  left: 150px;
  bottom: 220px;
}

}