/* @media (max-width:600px){
    .drawerItemLinks{
text-decoration: none;

display: flex;
padding: 12px;
font-size: 14px;

}}
@media (min-width:1536px){
    }
@media (max-width:1536px) {
    .drawerItemLinks{
        text-decoration: none;
        display: flex;
        align-items: center;
        padding: 12px;
        font-size: 16px;
        }
        
} */
.drawerItemLinks {
  text-decoration: none;
  display: flex;
  padding: 6px 12px;
  font-size: 16px;
}

.drawerItem li:last-child {
  margin-bottom: 60px;
  margin-top: auto;
}
.hightlight-body {
  position: relative;
}
.hightlight-body::after {
  content: '';
  background-color: rgb(0, 0, 0, 0.8);
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.side-logo{
  justify-content: center;
  margin-bottom: 30px;
}

@media screen and (max-width:1200px){
  .side-logo{
    justify-content: space-between;
  }
}
