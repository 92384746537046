
  .profileRecImage {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin-right: 15px;
  }
  .image-div{
    position: relative;
    padding: 15px;
    padding-bottom: 0px;
  }
  .image-div::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: unset;
    bottom: 0px;
    z-index: -1;
    height: 150px;
    width: 150px;
    background-color: #27245e;
  }
  .copyButton {
    color: #000;
    font-size: 12px;
    font-weight: 500;
    border: 2px solid rgba(0, 0, 0, 0.16);
    width: 60%;
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: center;
    border-radius: 5px;
  }
  .head-main{
    margin-bottom: 20px;
  }
  .head-main2{
    margin-bottom: 20px;
    row-gap: 10px;
  }
  .head-main .btn-rt-skw{
    -webkit-transform: skew(-22deg) translate(-10%, 0%);
    -moz-transform: skew(-22deg) translate(-10%, 0%);
    -ms-transform: skew(-22deg) translate(-10%, 0%);
    transform: skew(-22deg) translate(-10%, 0%);
    padding-left: 24px;
    padding-right: 24px;
    border-radius: 0px;
    margin: 0;

  }
  .head-main .btn-rt-skw svg{
    transform:skew(22deg);
    -webkit-transform: skew(22deg);
    -moz-transform: skew(22deg);
    -webkit-transform:skew(22deg);
  }
  .head-main h2{
    color: #333333;
    font-size: 24px;
    font-weight: 600;
  }
  .info-cust h3{
    text-transform: uppercase;
    font-weight: 600;
    font-family: Hind,sans-serif;
    font-size: 22px;
    color:#27245E;
    margin-bottom: 5px;
    margin-top: 0px;
  }
  .info-cust p{
    margin-bottom: 0px;
    margin-top: 0px;
  }
  .text-light{
    color:#ACACAC;
  }
  .subscription-box, .result-box{
    background-color: #27245E;
    color: #fff;
    border-radius: 6px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 45px 20px;
  }
  .subscription-box h4, .result-box h4{
    margin: 0px 0px 20px;
    font-size: 18px;
  }
  .subscription-btn, .result-btn {
    border: 1px solid #CDCAFC;
    background-color: #F5F4FF;
    padding: 5px 15px;
    border-radius: 30px;
    font-size: 12px;
    color:#27245E;
    font-weight: 600;
    text-align: center;
  }
  .subscription-btn span, .result-btn span{
    color: #CB2128;
  }
  .heading-bg{
    background-color: #EEF2FC;
    color: #27245E;
  }
  .heading-bg h3{
    font-size: 18px;
    font-weight: 600;
    padding: 6px 15px;
  }
  .stat-box li{
    background-color: #F5F4FF;
    padding: 15px 10px;
    min-width: 160px;
    border:1px solid #CDCAFC;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }  
  .stat-box ul{
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    column-gap: 15px;
    row-gap: 15px;
    margin-left: 0px;
    padding-left: 0px;
  }  
  .stat-box li h4{
    color:#CB2128;
    font-size: 16px;
    font-weight: 600;
    margin:0px
  }
  .stat-box li p{
    color:#27245E;
    margin:0px;
    font-size: 14px;
  }
  .about-info{
    display: flex;
  }
  .about-result{
    margin-top: 30px;
  }
  .result-box{
    border-radius: 0;
  }
  .tabs-cust {
      margin-top: 30px;
      border: 1px solid #CDCAFC;
      background: #F5F4FF;
  }
  .tabs-cust .MuiTabs-indicator{
    background-color: transparent;
  }
  /* .tabs-cust button{
    font-family: Hind,sans-serif;
  } */

/* @media (min-width: 600px) {
  .profileRecImage {
    width: 130px;
    height: 130px;
  }
  .copyButton {
    color: #000;
    font-size: 12px;
    font-weight: 500;
    border: 2px solid rgba(0, 0, 0, 0.16);
    width: 40%;
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: center;
    border-radius: 5px;
  }
}
@media (min-width: 900px) {
  .profileRecImage {
    min-width: 150px;
    min-height: 150px;
  }
  .copyButton {
    color: #000;
    font-size: 12px;
    font-weight: 500;
    border: 2px solid rgba(0, 0, 0, 0.16);
    width: 25%;
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: center;
    border-radius: 5px;
  }
} */
/* @media (min-width: 1200px) {
  .profileRecImage {
    width: 130px;
    height: 130px;
  }
  .copyButton {
    color: #000;
    font-size: 12px;
    font-weight: 500;
    border: 2px solid rgba(0, 0, 0, 0.16);
    width: 12%;
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: center;
    border-radius: 5px;
  }
} */
/* @media (min-width: 1536px) {
  .profileRecImage {
    width: 130px;
    height: 130px;
  }
  .image-div{
    position: relative;
  }
  .image-div::before {
    position: absolute;
    content: '';
    top: -10%;
    left: -18px;
    right: unset;
    bottom: 0px;
    z-index: -1;
    height: 350px;
    width: 288px;
    background-color: #27245e;
  }
  .copyButton {
    color: #000;
    font-size: 12px;
    font-weight: 500;
    border: 2px solid rgba(0, 0, 0, 0.16);
    width: 10%;
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: center;
    border-radius: 5px;
  }
} */
